<script>
import walletApi from '../apis/walletApi';
import emailApi from '../apis/emailApi';
import storage from '../storage';

export default {
  data() {
    return {
      data: {
        verificationCode: "",
        newPassword: ""
      },
      alert: {
        text: "",
        show: false
      },
      codeTime: 0
    }
  },
  created() {

  },
  methods: {
    //修改密码
    modification() {
      if (this.data.verificationCode == "") {
        this.alert.show = true
        this.alert.text = "Verification code cannot be empty"
        return
      }
      if (this.data.newPassword == "") {
        this.alert.show = true
        this.alert.text = "New password cannot be empty"
        return
      }
      let data = JSON.parse(JSON.stringify(this.data))
      data.newPassword = btoa(this.data.newPassword)
      this.load = true
      walletApi.modification(data, res => {
        this.load = false
        if (res.code != 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.$message.success("Success")
          this.alert.show = false
          this.data = {
            verificationCode: "",
            newPassword: ""
          }
        }
      })
    },
    code() {
      const email = localStorage.getItem("email")
      // alert(email)
      if (!email) {
        this.$router.push("/auth-login")
        return
      }
      //获取按钮禁用时间
      this.codeTime = 60
      const timer = setInterval(() => {
        this.codeTime--
        if (this.codeTime == 0) {
          clearInterval(timer)
        }
      }, 1000)
      emailApi.code({
        email: email
      }, res => {
        if (res.code != 200) {
          this.alert.show = true
          //错误返回信息
          this.alert.text = res.msg
        }
      })
    },
  }
}
</script>

<template>
  <div class="p-2">
    <el-row :gutter="10" class="mb-2">
      <el-col>
        <h4 class="text-primary">Payment Password Modification</h4>
      </el-col>
    </el-row>

    <el-row :gutter="10" class="mb-2">
      <el-col :span="6" :xs="24">
        <label style="line-height: 40px;">Mail Verification Code</label>
      </el-col>
      <el-col :span="18" :xs="24" style="display: flex;align-items: center;">
        <input type="text" class="form-control" placeholder="Enter Your Mail Verification Code" name="code"
               v-model="data.verificationCode"/>
        <div style="margin-left: 10px;">
          <div class="btn btn-primary" v-if="codeTime == 0" @click="code">Send</div>
          <div class="btn btn-primary disabled" v-else>{{ codeTime }}
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="mb-2">
      <el-col :span="6" :xs="24">
        <label style="line-height: 40px;">New Payment Password</label>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-input v-model="data.newPassword" type="password" placeholder="Enter Your New Payment Password"
                  show-password></el-input>
      </el-col>
    </el-row>
    <el-row v-if="!alert.show" style="margin-bottom: 10px;">
      <el-alert style="font-size: 12px;" show-icon title="Default payment password: 666777" type="warning"
                :closable="false"></el-alert>
    </el-row>
    <el-row v-else style="margin-bottom: 10px;">
      <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
    </el-row>
    <el-row :gutter="10" class="mb-2">
      <el-col :span="4">
        <b-button type="primary" @click="modification">Submit</b-button>
      </el-col>
    </el-row>
  </div>
</template>


<style>
</style>
