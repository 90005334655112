<template>
  <div>
    <el-row style="
      position: relative;
			margin-top: 50px;">
      <el-button type="primary" icon="el-icon-back" @click="back" style="background: #fff3d6;color:black;font-weight:500;">Dashboard</el-button>
    </el-row>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  methods: {
    back() {
      console.log('i click')
      // this.$emit("backClick")
      this.$router.push('/profile/overview')
    }
  },
  created() {
    // alert(123)
  }
}


</script>

<style>
</style>
