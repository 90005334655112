<script>
import { Carousel, Slide } from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import walletApi from "@/apis/walletApi";
import storage from '@/storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import Withdrawal from "@/components/withdrawal.vue";
import PayTaxes from "@/components/payTaxes";
import Modification from "@/components/modification.vue";
import back from "@/components/back.vue";

/**
 * Index-1
 */
export default {
  data() {
    return {
      wallet: undefined,
      walletLoad: 0,
      login: false,
      walletForm: {
        name:"",
        pageNum: 1,
        pageSize: 8
      },
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Withdrawal,
    Modification,
    back,
    PayTaxes
  },
  created() {
    //获取项目列表
    walletApi.balance(res => {
      this.wallet = res.data
    })
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  },
  methods: {
    loadmore(page) {
      //开始加载
      this.walletLoad = 1
      this.walletForm.pageNum = page
      //获取项目列表
      walletApi.list(this.walletForm, res => {
        this.walletLoad = 0
        Object.assign(this.wallet, res.data)
      })
    },
    //用于手机加载
    loadmore2() {
      this.walletForm.pageNum++
      //开始加载
      this.walletLoad = 1
      let pages = Math.ceil(this.wallet.totalCount / this.walletForm.pageSize)
      //获取项目列表
      if (pages >= this.walletForm.pageNum) {
        walletApi.list(this.walletForm, res => {
          res.data.result.unshift(...this.wallet.result)
          Object.assign(this.wallet, res.data)
          this.walletLoad = 0
          if (pages <= this.walletForm.pageNum) this.walletLoad = -1//结束加载
        })
      } else {
        this.walletLoad = -1;
      }
    },
    indexChange(index) {
      this.index = index;
    }
  },
};
</script>

<template>
  <div style="margin-bottom:50px;">
    <!-- 项目列表 -->
    <section class="section" style="padding-bottom: 0px;margin-top: -100px;z-index:2;">
      <back />
      <div class="container">
        <!--        <el-row :gutter="20">-->
        <!--          <el-col :span="14" :xs="24">-->
        <!--            <h4>Wallet</h4>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <el-row :gutter="20" class="mt-4">
          <el-col :span="24">
            <el-row :gutter="10" class="mb-2">
              <el-col>
                <h4 class="text-primary">Refund</h4>
                <p style="color:orange;">Company covers the taxes and fees for regular investments, but for refunds, customers are responsible for the associated taxes and fees.</p>
              </el-col>
            </el-row>
            <div class="card p-2 mt-2 border-0 shadow bg-light">
              <PayTaxes></PayTaxes>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>
